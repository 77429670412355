import { useEffect, useRef } from 'react';

function useDOMChange(callback = () => {}) {
    const observer = useRef(null);

    useEffect(() => {
        observer.current = new MutationObserver(callback);
        observer.current.observe(document, {
            childList: true,
            subtree: true,
        });

        return () => observer.current.disconnect();
    }, [callback]);
}

export default useDOMChange;

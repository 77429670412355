// Prompt used to generate this file using Anthropic Claude:
// write a pure JS function "adjustContrast" that accepts a DOM Element
// node and recursively goes through it's children to make sure that each node has enough
// contrast "color" with its own or its ancestors "background" color.
// The color contrast requirement is WCAG AA.
// If there isn't enough contrast for any of the nodes then it should calculate a
//  "color" value that satisfies the contrast requirement with the "background" value.
//  It can invert the color in such a case.
//  when such a color is found it should update the style of that node accordingly

/**
 * Parses the RGB or RGBA color string and returns an array of color components.
 *
 * @param {string} color - The RGB or RGBA color string to parse.
 * @returns {number[]} An array of color components [R, G, B].
 * @throws {Error} If the color format is invalid.
 */
const parseRGB = (color) => {
    const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    const rgbaMatch = color.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?)\)$/);

    if (rgbMatch) {
        return [parseInt(rgbMatch[1], 10), parseInt(rgbMatch[2], 10), parseInt(rgbMatch[3], 10)];
    } if (rgbaMatch) {
        return [parseInt(rgbaMatch[1], 10), parseInt(rgbaMatch[2], 10), parseInt(rgbaMatch[3], 10)];
    }
    throw new Error(`Invalid RGB or RGBA color format: ${color}`);
};

/**
 * Calculates the relative luminance of a color.
 *
 * @param {string} color - The color string for which to calculate the luminance.
 * @returns {number} The relative luminance value.
 */
const getLuminance = (color) => {
    const rgb = parseRGB(color);
    const [r, g, b] = rgb.map((component) => {
        const c = component / 255;
        return c <= 0.03928
            ? c / 12.92
            : ((c + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

/**
 * Calculates the contrast ratio between two colors.
 *
 * @param {string} color1 - The first color string.
 * @param {string} color2 - The second color string.
 * @returns {number} The contrast ratio.
 */
const getContrastRatio = (color1, color2) => {
    const luminance1 = getLuminance(color1);
    const luminance2 = getLuminance(color2);
    return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
};

/**
 * Checks if the contrast between two colors meets a specific WCAG level.
 *
 * @param {string} color - The foreground color string.
 * @param {string} backgroundColor - The background color string.
 * @param {number} [wcagLevel=7] - The WCAG level (contrast ratio) to check against.
 * @returns {boolean} True if the contrast meets the WCAG level, false otherwise.
 */
const hasSufficientContrast = (color, backgroundColor, wcagLevel = 7) => {
    const contrastRatio = getContrastRatio(color, backgroundColor);
    return contrastRatio >= wcagLevel;
};

/**
 * Inverts the given color.
 *
 * @param {string} color - The color string to invert.
 * @returns {string} The inverted color string.
 */
const invertColor = (color) => {
    const rgb = parseRGB(color);
    const invertedRGB = rgb.map((component) => 255 - component);
    return `rgb(${invertedRGB[0]}, ${invertedRGB[1]}, ${invertedRGB[2]})`;
};

/**
 * Calculates a darker color by decrementing each color component towards the contrast color.
 *
 * @param {string} color - The original color string.
 * @param {string} contrastColor - The contrast color string.
 * @returns {string} The darker color string.
 */
const getDarkerColor = (color, contrastColor) => {
    const rgb = parseRGB(color);
    const contrastRGB = parseRGB(contrastColor);

    const darkerRGB = rgb.map(
        (component, index) => (
            Math.max(component - Math.abs(contrastRGB[index] - component), 0)
        ),
    );

    return `rgb(${darkerRGB[0]}, ${darkerRGB[1]}, ${darkerRGB[2]})`;
};

/**
 * Calculates a lighter color by incrementing each color component towards the contrast color.
 *
 * @param {string} color - The original color string.
 * @param {string} contrastColor - The contrast color string.
 * @returns {string} The lighter color string.
 */
const getLighterColor = (color, contrastColor) => {
    const rgb = parseRGB(color);
    const contrastRGB = parseRGB(contrastColor);

    const lighterRGB = rgb.map(
        (component, index) => (
            Math.min(component + Math.abs(contrastRGB[index] - component), 255)
        ),
    );

    return `rgb(${lighterRGB[0]}, ${lighterRGB[1]}, ${lighterRGB[2]})`;
};

/**
 * Finds the closest color that satisfies the contrast requirement by adjusting the given color.
 *
 * @param {string} color - The foreground color string.
 * @param {string} backgroundColor - The background color string.
 * @param {number} [wcagLevel=7] - The WCAG level (contrast ratio) to satisfy.
 * @returns {string} The closest color string that satisfies the contrast requirement.
 */
const getClosestContrastColor = (color, backgroundColor, wcagLevel) => {
    const luminance = getLuminance(backgroundColor);
    const contrastColorLight = 'rgb(255, 255, 255)';
    const contrastColorDark = 'rgb(0, 0, 0)';

    const contrastColor = luminance > 0.5 ? contrastColorDark : contrastColorLight;

    if (hasSufficientContrast(color, contrastColor, wcagLevel)) {
        return contrastColor;
    }
    const colorLuminance = getLuminance(color);
    const luminanceDiff = colorLuminance - luminance;

    if (luminanceDiff > 0) {
        return getLighterColor(color, contrastColor);
    }
    return getDarkerColor(color, contrastColor);
};

/**
 * Calculates the contrast color for a given color and background color,
 *  considering the WCAG level.
 * If an inverted color meets the contrast requirement,
 *  it is returned; otherwise, the closest color is found.
 *
 * @param {string} color - The foreground color string.
 * @param {string} backgroundColor - The background color string.
 * @param {number} wcagLevel - The WCAG level (contrast ratio) to satisfy.
 * @returns {string} The contrast color string.
 */
const getContrastColor = (color, backgroundColor, wcagLevel) => {
    const invertedColor = invertColor(color);
    if (hasSufficientContrast(invertedColor, backgroundColor, wcagLevel)) {
        return invertedColor;
    }
    return getClosestContrastColor(color, backgroundColor, wcagLevel);
};

/**
 * Recursively adjusts the contrast of a DOM element and its
 * children to ensure sufficient contrast between text color and background color.
 *
 * @param {HTMLElement} node - The DOM element to adjust the contrast for.
 * @param {number} [wcagLevel=7] - The WCAG level (contrast ratio) to satisfy.
 * @param {string} [parentBackgroundColor] - The background color of the parent element
 *  (used for transparent backgrounds).
 * @returns {void}
 */
// eslint-disable-next-line default-param-last
const adjustContrast = (node, wcagLevel = 7, parentBackgroundColor) => {
    let { backgroundColor } = getComputedStyle(node);
    const { color } = getComputedStyle(node);

    if (backgroundColor?.indexOf('rgba(0, 0, 0, 0)') !== -1 && parentBackgroundColor) {
        backgroundColor = parentBackgroundColor;
    }

    if (!hasSufficientContrast(color, backgroundColor, wcagLevel)) {
        const contrastColor = getContrastColor(color, backgroundColor, wcagLevel);
        node.style.setProperty('color', contrastColor);
    }

    const { childNodes } = node;
    for (let i = 0; i < childNodes.length; i += 1) {
        const childNode = childNodes[i];
        if (childNode.nodeType === Node.ELEMENT_NODE) {
            adjustContrast(childNode, wcagLevel, backgroundColor);
        }
    }
};

export default adjustContrast;

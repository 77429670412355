/* eslint-disable react/no-unused-prop-types */
import withLogger from '@concur/core-ui-shell/lib/hoc/withLogger';
import compose from '@concur/core-ui-shell/lib/utils/compose';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withLoggerData from '../utils/withLoggerData';
import adjustContrast from '../utils/adjustContrast';
import useDOMChange from '../utils/useDOMChange';

const ThemeAdjust = ({
    configOptions = {},
    themeName,
}) => {
    const {
        targetSelectors = [],
    } = configOptions;

    const handleLoad = () => {
        if (
            !targetSelectors?.length
            // assuming that the custom content
            // was created for a light theme (like Gateway or Horizon Morning)
            // we should run the color inversion and contrast adjustments
            // only for dark themes.
            || (
                themeName?.indexOf?.('dark') === -1
                && themeName?.indexOf?.('hcb') === -1
            )
        ) {
            return;
        }
        targetSelectors?.forEach((targetSelector) => {
            const targets = document?.querySelectorAll(targetSelector);
            if (!targets?.length) return;
            targets?.forEach((node) => (adjustContrast(node, themeName?.indexOf('dark') === -1 ? 7 : 4.5)));
        });
    };

    useDOMChange(handleLoad);

    useEffect(() => {
        handleLoad();
    }, [targetSelectors]);

    return (
        <div />
    );
};

ThemeAdjust.displayName = 'ThemeAdjust';

ThemeAdjust.propTypes = {
    brandingId: PropTypes.number,
    themeName: PropTypes.string,
    configOptions: PropTypes.shape({
        targetSelectors: PropTypes.arrayOf(PropTypes.string),
    }),
};

export default compose(
    withLoggerData,
    withLogger,
)(ThemeAdjust);
